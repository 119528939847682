/* eslint-disable angular/window-service, angular/document-service */
import 'jquery';
import 'pako';
import 'underscore';
import 'moment';
import 'moment/locale/sr';
import 'moment/locale/ro';
import 'socket.io-client';
import 'jwt-decode';
import 'angular';
import 'angular-animate';
import 'angular-ui-router';
import 'angular-translate';
import 'angular-uuid';
import '@nsoft/seven-ng-socket/dist/nab-ng-socket';
import 'ng-file-upload/dist/ng-file-upload';
import '@nsoft/seven-ng-client-validator/dist/validator';
import '@nsoft/seven-ng-lib/dist/seven-ng-lib';
import '../vendor/polyfills';
import '../vendor/ui-bootstrap-custom-tpls-2.5.0.min';
import './modules/bootstrap';
import './app';

// hack, remove it when all node_modules drop SevenClientCore
angular.module('SevenClientCore', []);
// // make sure the DOM is fully loaded before bootstrapping angular app
angular.element(document).ready(function () {
  var LOCAL_DATA = {};
  var injector = angular.injector(['ng']);
  var $http = injector.get('$http');

  window.addEventListener('contextmenu', function (e) {
    e.preventDefault();
  });

  function setNabENVNgConstant(env) {
    angular.module('7Shop.Core').constant('NabENV', env); // we need environment
  }

  function init() {
    // eslint-disable-next-line no-undef
    var configPath = `data/config.${GIT_HASH}.json`;
    // why replace? cause grunt-cache-bust won't find file if there is no slash on first place
    //  same is done in bootstrap/modules.js
    $http.get(configPath).then(function (configDataResponse) {
      // we have environment set already by build process so set data
      LOCAL_DATA.env = configDataResponse.data;
      window.SEVEN_LOCAL_DATA = LOCAL_DATA;

      // some builders (RPM) still want to write their own version,
      // they are writing version to .global.version node,
      // if version is set, use it.
      // this will be removed soon
      if (LOCAL_DATA.env.global && LOCAL_DATA.env.global.version) {
        LOCAL_DATA.env.version = LOCAL_DATA.env.global.version;
      }

      setNabENVNgConstant(LOCAL_DATA.env);

      angular.bootstrap(
        document,
        ['7Shop']
      );
    }).catch(function (err) {
      console.log('[NGS] Fail fetching config.json', err); // eslint-disable-line
    });
  }

  init();
});
