import router from 'angular-ui-router';
import translate from 'angular-translate';
import '@nsoft/seven-ng-socket/dist/nab-ng-socket';
import '@nsoft/seven-ng-lib/dist/seven-ng-lib';
import sInputSwitchDirective from './directives/sInputSwitch/sInputSwitchDirective';
/**
  * Common directives, services, filters.
  * @module "7Shop.Common"
  */
angular.module('7Shop.Common', [
  router,
  translate,
  'ngLib'
]).directive('sInputSwitch', sInputSwitchDirective);
