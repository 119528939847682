import angular from 'angular';
import translate from 'angular-translate';
import router from 'angular-ui-router';
import 'angular-uuid';
import { each } from 'underscore';
import '@nsoft/seven-ng-client-validator/dist/validator';
import '@nsoft/seven-ng-lib/dist/seven-ng-lib';
import commonModule from '../common';
import modulesModule from '../modules';
import userModule from '../modules/user';
import sevenMoneyOverride from './filters/sevenMoneyOverrideFilter';
import CloudflareService from './services/cloudflareService';
import CmsFormsParserService from './services/cmsFormsParserService';
import GravitySettingsProvider from './gravity/gravitySettingsProvider';
import sGravityControlComponent from './components/sGravityControlComponent';
import sEquals from './directives/sEqualsDirective';
import sKeyEvents from './keyEvents/keyEventsDirective';
import KeyEventsSvc from './keyEvents/keyEventService';

(function () {
  /**
   * @module "7Shop.Core"
   */
  angular.module('7Shop.Core', [
    translate,
    router,
    'ngLib',
    'nabValidation',
    commonModule,
    modulesModule,
    userModule
  ]).run(function (
    $rootScope,
    Shortcuts
  ) {
    /**
     *
     */
    $rootScope.$on(
      '7S:Widget.UpdateProductSettings',
      /**
       *
       * @param e
       * @param eventData
       * @param eventData.productId
       * @param eventData.data
       * @param eventData.data.shortcuts
       * @param eventData.data.color
       * @param eventData.data.ticketCheckFormatter
       */
      function (e, eventData) {
        if (eventData.data.shortcuts) {
          each(eventData.data.shortcuts, function (shortcut) {
            if (!shortcut.category) {
              shortcut.category = eventData.productId;
            }
            Shortcuts.registerShortcuts([shortcut]);
          });
        }
      }
    );
  }).factory('KeyEventsSvc', KeyEventsSvc)
    .filter('sevenMoneyOverride', sevenMoneyOverride)
    .service('CloudflareService', CloudflareService)
    .service('CmsFormsParserService', CmsFormsParserService)
    .provider('GravitySettings', GravitySettingsProvider)
    .directive('sEquals', sEquals)
    .directive('sKeyEvents', sKeyEvents)
    .component('sGravityControl', sGravityControlComponent);
})();
